import { API } from "aws-amplify";
import axios from "axios";
import {
  PrescriptionFilterTypesMap,
  addPrescriptionUrlMap,
} from "../Components/prescription/utils/prescriptionTypeUtils";
import { favoriteValidator } from "../schemas/favorite";
import { isProduction } from "../config";
import { getAxiosErrorMessage } from "./handleError";

const chatList = [
  "motivated",
  "mitochondria",
  "poshdoc",
  "jackHealth",
  "pureLife",
  // "orderlyMeds",
  "manyLabs",
  "newHopeWellness",
  "mailMyMeds",
  "readyRx",
  "fitpal",
  "drDerm",
  "oatMeds",
  "undefined",
  "totalMaleRx",
  "doctorApprovedMeds",
  "braveRx",
];

const csApiList = [
  "motivated",
  "mitochondria",
  "poshdoc",
  "orderlyMeds",
  "gulaworld",
  "jackHealth",
  "pureLife",
  "manyLabs",
  "newHopeWellness",
  "daybreak",
  "fitpal",
  "drDerm",
  "oatMeds",
  "undefined",
  "doctorApprovedMeds",
  "braveRx",
];

const csCompanies = [
  "myDrHank",
  "247Health",
  "scoreBlue",
  "medsForMen",
  "smartDoctors",
  "sunrise",
  "aspectHealth",
  "jase",
  "lifebac",
  "ilMakiage",
  "bodygoodstudio",
  "venus",
  "dosable",
  "flexHealth",
  "sikaHealth",
  "urway",
  "siphox",
  "architekTangl",
  "maion",
  "zepmeds",
  "mailMyMeds",
  "emed",
  "readyRx",
  "genRx",
  "fitScripts",
  "totalMaleRx",
  "healthHavenRx",
  "ubacare",
];

const descopeSignin = async (loginData, setIsVerify) => {
  try {
    const response = await axios({
      method: "POST",
      url: "https://auth.belugahealth.com/auth/signin",
      data: loginData,
      // crossdomain: true,
      // withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log("signin response", response);
    setIsVerify(true);
  } catch (err) {
    console.log(err);
    return;
  }
};

const descopeVerify = async (loginData, setUserId) => {
  try {
    const response = await axios({
      method: "POST",
      url: "https://auth.belugahealth.com/auth/verify",
      // response: true,
      // withCredentials: true,
      // crossdomain: true,
      data: loginData,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log("verify response", response);
    setUserId(response.data.user.userId);
  } catch (err) {
    console.log(err);
    return;
  }
};

const getUser = async (id, getUserInfo) => {
  const response = await API.post("brains", "/auth/descopeLogin", {
    credentials: "include",
    withCredentials: true,
    body: {
      userId: id,
    },
  });
  console.log("backend login response", response);
  getUserInfo(response.data);
};

const getTransmissionErrors = async (token, user, setTransErrors, setDsUrl) => {
  if (user.dosespotID) {
    try {
      const response = await API.post(
        "brains",
        "/dosespot/transmissionErrors",
        {
          credentials: "include",
          body: {
            clinicianID: user.dosespotID,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status !== 200) {
        console.error(response);
      }
      setTransErrors(response.errors.length);
      setDsUrl(response.dsUrl);
    } catch (err) {
      console.log(err);
      return err;
    }
  }
};

const login = async (e, loginData, setMessage, getUserInfo) => {
  e.preventDefault();
  try {
    const loginResponse = await API.post("brains", "/auth/login", {
      credentials: "include",
      body: loginData,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (loginResponse.status !== 200) {
      console.error(loginResponse);
    }
    if (loginResponse.info === "login successful") {
      setMessage("");
      localStorage.setItem("jwt", loginResponse.token);
      sessionStorage.setItem("jwt", loginResponse.token);
      getUserInfo(loginResponse.data);
    } else {
      setMessage(loginResponse.info);
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getFilteredPatients = async (token, user, setFilteredPatients) => {
  try {
    const patientResponse = await API.get(
      "brains",
      `/patient/filtered/${user._id}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (patientResponse.status !== 200) {
      console.error(patientResponse);
    }
    const justActive = patientResponse.data.filter((patient) =>
      patient.visits.some((visit) => visit.status === "active")
    );
    setFilteredPatients(justActive);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getCompanies = async (token, setCompanies) => {
  try {
    const response = await API.get("brains", "/company", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setCompanies(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getPlatformCompanyNames = async (token, setPlatformCompanies) => {
  try {
    const response = await API.get("brains", "/company/platformCompanyNames", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setPlatformCompanies(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createCompany = async (token, companyData) => {
  try {
    const payload = {
      name: companyData.companyName,
      visitTypes: companyData.acceptableVisitTypes.map((visitType) => {
        return {
          type: visitType.type,
          phone: visitType.phone,
          rxText: visitType.rxText,
        };
      }),
      country: companyData.country || "US",
      customerServiceEmail: companyData.email,
      hasCSApi: companyData.hasCSApi,
      hasChatApi: companyData.hasChatApi,
      isDSCompany: true,
      isPlatform: false,
      isPlatformSubCompany: companyData.isPlatformSubCompany,
      idVerified: companyData.idVerified,
      labResults: companyData.labResults,
      pharmacyList: companyData.pharmacyList,
      displayName: companyData.displayName,
      docsAccessEmails: companyData.docsAccessEmails,
    };

    // backend will handle set api key using the platformCompanyName
    if (!companyData.platformCompanyName) {
      payload.apiKey = {
        staging: isProduction() ? "" : companyData.apiKey,
        prod: isProduction() ? companyData.apiKey : "",
      };
    } else {
      payload["platformCompany"] = companyData.platformCompanyName;
    }
    const response = await API.post("brains", "/company", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    });
    if (response.status !== 200) {
      console.error("Response error", response);
      throw new Error(JSON.stringify(response));
    }
    return response.data;
  } catch (err) {
    console.error(err);
    if (axios.isAxiosError(err)) {
      const message = getAxiosErrorMessage(err);
      throw new Error(message);
    }

    throw err;
  }
};

const updateCompany = async (token, existingCompany, companyData) => {
  try {
    const payload = {
      name: companyData.companyName,
      visitTypes: companyData.acceptableVisitTypes.map((visitType) => {
        return {
          type: visitType.type,
          phone: visitType.phone,
          rxText: visitType.rxText,
        };
      }),
      country: companyData.country || existingCompany.country,
      customerServiceEmail: companyData.email || existingCompany.email,
    };

    if (companyData.apiKey) {
      payload.apiKey = {
        staging: isProduction() ? "" : companyData.apiKey,
        prod: isProduction() ? companyData.apiKey : "",
      };
    }

    if (companyData.apiKeyDashboard) {
      payload.apiKeyDashboard = {
        staging: isProduction() ? "" : companyData.apiKeyDashboard,
        prod: isProduction() ? companyData.apiKeyDashboard : "",
      };
    }

    const response = await API.put(
      "brains",
      `/company/${existingCompany._id}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      }
    );
    if (response.status !== 200) {
      console.error(response);
      throw response;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const generateCompanyDocumentation = async (token, companyId) => {
  try {
    const payload = {
      companyId
    };
    const response = await API.post("brains", `/company/generateDocumentation`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: payload,
    });
    if (response.status !== 200) {
      throw response;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getPatient = async (token, id, setCurrentPatient) => {
  const response = await API.get("brains", `/patient/${id}`, {
    credentials: "include",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    console.error(response);
  }
  setCurrentPatient(response.data);
};

const getPatients = async (token, setPatients) => {
  try {
    const response = await API.get("brains", "/patient", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setPatients(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getPatientsAdmin = async (token, setFilteredPatientsAdmin) => {
  try {
    const response = await API.get("brains", "/patient/admin", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setFilteredPatientsAdmin(response.data.admin);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getResolvedStats = async (
  token,
  setTotalReferred,
  setTotalPrescribed
) => {
  try {
    const response = await API.get("brains", "/report/resolved", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setTotalReferred(response.data.referred);
    setTotalPrescribed(response.data.prescribed);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getResolvedFull = async (token, setFullResolved, setFilteredResolved) => {
  try {
    const response = await API.get("brains", "/report/resolvedFull", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setFullResolved(response.data);
    setFilteredResolved(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getUserResolved = async (token, setFull, setFiltered) => {
  try {
    const response = await API.get("brains", "/doc/resolvedVisits", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setFull(response.data);
    setFiltered(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getGuidelines = async (token, user, setGuidelines) => {
  try {
    const guideResponse = await API.get(
      "brains",
      `/doc/guidelines/${user._id}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (guideResponse.data.length) {
      setGuidelines(guideResponse.data);
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getOrders = async (token, visit, setOrders) => {
  try {
    const body = {
      visitId: visit._id,
    };
    const orderResponse = await API.post("brains", "/order/belugaOrders", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
    });
    if (orderResponse.status !== 200) {
      return;
    }
    setOrders(orderResponse.data);
  } catch (err) {
    console.log("error in getOrders", err);
  }
};

const getAutoClosed = async (
  token,
  setFullAutoClosed,
  setFilteredAutoClosed
) => {
  try {
    const response = await API.post("brains", "/report/autoClosedFull", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {},
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setFullAutoClosed(response.data);
    setFilteredAutoClosed(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getDoctors = async (token, setDocs, setFilteredDocs) => {
  try {
    const doctorResponse = await API.get("brains", "/doc", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (doctorResponse.status !== 200) {
      console.error(doctorResponse);
    }
    setDocs(doctorResponse.data);
    setFilteredDocs(doctorResponse.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getDoctorsTrimmed = async (token, visit, setDocs) => {
  try {
    const doctorResponse = await API.post("brains", "/doc/trimmed", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        company: visit.company,
        visitType: visit.visitType,
      },
    });
    if (doctorResponse.status !== 200) {
      console.error(doctorResponse);
    }
    setDocs(doctorResponse.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getPhotos = async (token, id, setIdPhotos, setIsPhotosLoading) => {
  setIsPhotosLoading(true);
  const response = await API.get("brains", `/visit/${id}/photos`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    console.error(response);
    throw Error(response);
  }
  setIdPhotos(response.data);
  setIsPhotosLoading(false);
};

const getVideo = async (token, id, setCurrentVideo) => {
  const response = await API.get("brains", `/visit/${id}/video`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    console.error(response);
    throw Error(response);
  }
  setCurrentVideo(response.data);
};

const getProducts = async (token, visit, setSKUs) => {
  try {
    const body = {
      company: visit.company,
      visitType: visit.visitType,
    };
    const response = await API.post("brains", "/product/getProducts", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    });
    if (response.status !== 200) {
      return;
    }
    setSKUs(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getChatHistory = async (token, id, setGettingChats, setChatState) => {
  try {
    setGettingChats(true);
    const response = await API.get("brains", `/chat/getChats/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setChatState(response.data);
    setGettingChats(false);
  } catch (err) {
    console.log(err);
    return;
  }
};

const getVisit = async (token, id) => {
  try {
    const response = await API.get("brains", `/visit/${id}`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return;
  }
};

const getCsvRecords = async (token, id, setCsvRecords) => {
  try {
    const response = await API.get("brains", `/hio/getCsvRecords/${id}`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setCsvRecords(response.data);
    return;
  } catch (err) {
    console.log(err);
    return;
  }
};

const getOutputFile = async (token, id, patientId, setVisit, updatePatient) => {
  try {
    const body = {
      patientId,
    };
    const response = await API.post("brains", `/hio/hioOutputFile/${id}`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    });
    setVisit(response.data.visit);
    updatePatient(response.data.patient);
    return;
  } catch (err) {
    console.log(err);
    return;
  }
};

const logout = async (token, setUser, setLoggedIn, setAdmin, navigate) => {
  try {
    await API.get("brains", "/auth/logout", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    localStorage.removeItem("jwt");
    sessionStorage.removeItem("jwt");
    setUser({});
    setLoggedIn(false);
    setAdmin(false);
    navigate("/");
  } catch (err) {
    console.log(err);
    return err;
  }
};

const checkLogin = async (
  localToken,
  sessionToken,
  setIsCheckingLogin,
  setUser,
  navigate,
  getUserInfo
) => {
  try {
    if (localToken && sessionToken) {
      setIsCheckingLogin(true);
      const response = await API.get("brains", "/auth/loggedIn", {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      });
      setIsCheckingLogin(false);
      if (response.name && response.name === "TokenExpiredError") {
        localStorage.removeItem("jwt");
        setUser({});
        navigate("/");
        return;
      } else {
        getUserInfo(response.data);
      }
    } else {
      localStorage.removeItem("jwt");
      setUser({});
      navigate("/");
    }
  } catch (err) {
    console.log(err);
  }
};

const changePassword = async (
  token,
  user,
  newPassword,
  confirmPassword,
  setUserState,
  setShowPasswordModal,
  setMessage
) => {
  try {
    if (newPassword === confirmPassword && newPassword.length) {
      const passwordChange = {
        password: newPassword,
      };
      const response = await API.put("brains", `/doc/${user._id}`, {
        credentials: "include",
        body: passwordChange,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status !== 200) {
        console.error(response);
      }
      setUserState(response.data);
      setShowPasswordModal(false);
      setMessage("");
    } else {
      setMessage("Please make sure you confirm your new password");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

const sendChat = async (token, data, orderId, setOrders) => {
  try {
    const response = await API.post(
      "brains",
      `/order/belugaSendChat/${orderId}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      }
    );
    if (response.status !== 200) {
      return;
    }
    setOrders(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const cancelOrder = async (token, orderId, visitId, setOrders, isRefund) => {
  try {
    const pathname = isRefund ? "cancelRefund" : "cancel";
    const body = {
      visitId,
    };
    const response = await API.post("brains", `/order/${pathname}/${orderId}`, {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    });
    if (response.status !== 200) {
      return;
    }
    setOrders(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createOrder = async (token, orderData, setOrders) => {
  try {
    const response = await API.post("brains", "/order/", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: orderData,
    });
    if (response.status !== 200) {
      return;
    }
    setOrders(response.data);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const sendCSMessage = async (
  token,
  CSMessage,
  setShowCSModal,
  setCSMessage,
  visit,
  firstName,
  lastName,
  phone
) => {
  try {
    const setPathname = () => {
      switch (true) {
        case /^bask/.test(visit.company):
        case csApiList.includes(visit.company):
          return "sendCSMessage";
        default:
          return "supportEmail/sendSES";
      }
    };
    const pathname = setPathname();
    await API.post("brains", `/visit/${pathname}`, {
      credentials: "include",
      body: CSMessage,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setShowCSModal(false);
    setCSMessage({
      firstName,
      lastName,
      phone,
      visitId: visit._id,
      content: "",
      company: visit.company,
    });
  } catch (err) {
    console.log(err);
    return;
  }
};

const sendText = async (
  token,
  visit,
  id,
  admin,
  message,
  setError,
  setChatState,
  updatePatient,
  setCurrentVisit,
  canResolve,
  handleResolve
  // setCanResolve
) => {
  try {
    const setPathname = () => {
      switch (true) {
        case /^bask/.test(visit.company):
        case /^carePortals/.test(visit.company):
        case chatList.includes(visit.company):
          return "sendChat";
        case visit.company === "hio":
          if (
            visit.visitType === "acr" ||
            visit.visitType === "postResultAcr"
          ) {
            return "sendHIOChat";
          } else {
            return "sendSMS";
          }
        case visit.company === "jase":
          return "sendVariableChat";
        default:
          return "sendSMS";
      }
    };
    const pathname = setPathname();

    const metaMessage = {
      visitId: visit._id,
      body: message.body,
      to: message.to,
      admin,
    };
    const response = await API.post("brains", `/chat/${pathname}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: metaMessage,
    });
    if (response.status !== 200) {
      setError(true);
      // does this push out into the catch?
      throw Error(response.statusText);
    }
    setChatState(response.data.patient.chat);
    updatePatient(response.data.patient);
    setCurrentVisit(response.data.visit);
    if (canResolve) {
      await handleResolve();
      // setCanResolve(false);
    }
  } catch (err) {
    setError(true);
    return err;
  }
};

const sendPhotoRequestSMS = async (
  token,
  visit,
  id,
  admin,
  message,
  setError,
  setChatState,
  updatePatient,
  setCurrentVisit
) => {
  try {
    const pathname = "sendSMS";

    const metaMessage = {
      visitId: visit._id,
      body: message.body,
      to: message.to,
      admin,
    };
    const response = await API.post("brains", `/chat/${pathname}/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: metaMessage,
    });
    if (response.status !== 200) {
      setError(true);
      // does this push out into the catch?
      throw Error(response.statusText);
    }
    setChatState(response.data.patient.chat);
    updatePatient(response.data.patient);
    setCurrentVisit(response.data.visit);
  } catch (err) {
    setError(true);
    return err;
  }
};

const updateVisit = async (
  token,
  user,
  patientId,
  visitId,
  data,
  setCurrentVisit,
  setUpdatedPatient
) => {
  try {
    const response = await API.put("brains", `/visit/${visitId}`, {
      credentials: "include",
      body: {
        userId: user._id,
        patientId,
        data,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }

    setCurrentVisit(response.data.updatedVisit);
    setUpdatedPatient(response.data.updatedPatient);
    return response.data.updatedVisit;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const updateVisitVideoStatus = async (
  token,
  visitId,
  data,
  setCurrentVisit
) => {
  try {
    const response = await API.post("brains", `/scheduling/status`, {
      credentials: "include",
      body: {
        ...data,
        visitId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
      return { error: response };
    }

    setCurrentVisit(response.data.updatedVisit);

    return response;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

const updatePatientDemo = async (
  token,
  patientId,
  patientDemo,
  setUpdateError,
  setUpdatedPatient,
  setShowPatientModal,
  setPatientDemo
) => {
  try {
    const response = await API.put("brains", `/patient/${patientId}`, {
      credentials: "include",
      body: patientDemo,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
      setUpdateError("Patient could not be updated with this information.");
      return;
    }
    setUpdateError("");
    setUpdatedPatient(response.data);
    setShowPatientModal(false);
    setPatientDemo({});
  } catch (err) {
    console.log(err);
    return err;
  }
};

const updateDoc = async (
  token,
  updatingDoc,
  id,
  setUpdatedDoc,
  setChangePasswordInput,
  setShow,
  setMessage
) => {
  try {
    if (
      !!updatingDoc.firstName &&
      !!updatingDoc.lastName &&
      !!updatingDoc.email &&
      !!updatingDoc.phone &&
      updatingDoc.stateLicenses.length &&
      updatingDoc.acceptableVisitTypes.length &&
      updatingDoc.companies.length &&
      [true, false].includes(updatingDoc.youthFilter) &&
      [true, false].includes(updatingDoc.admin) &&
      [true, false].includes(updatingDoc.surge)
    ) {
      const response = await API.put("brains", `/doc/${id}`, {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: updatingDoc,
      });
      if (response.status !== 200) {
        console.error(response);
        throw Error(response.statusText);
      }
      setUpdatedDoc(response.data);
      setChangePasswordInput(false);
      setShow(false);
    } else {
      setMessage("Please make sure every field is filled in correctly");
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

const bulkUpdateDoctor = async (token, setMessage, data) => {
  try {
    const response = await API.post("brains", "/doc/bulkUpdateCompany", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return null;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return null;
  }
};

const updateAdminUserCompanies = async (
  token,
  user,
  data,
  setUserState,
  setShowModal,
  setMessage
) => {
  try {
    const response = await API.put("brains", `/doc/${user._id}`, {
      credentials: "include",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    setUserState(response.data);
    setShowModal(false);
    setMessage("");
  } catch (err) {
    console.log(err);
    return err;
  }
};

const switchVisit = async (token, currentVisitId, futureVisitId, admin) => {
  try {
    const response = await API.post("brains", `/visit/${currentVisitId}`, {
      credentials: "include",
      body: {
        futureVisitId,
        admin,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createDropboxPDF = async (token, patient, _id, pdfContent) => {
  const body = {
    patientId: patient._id,
    visitId: _id,
    rxName: pdfContent.medication,
    rxDose: pdfContent.dosage,
    rxNumber: pdfContent.quantity,
    rxRefills: pdfContent.refills,
    directions: pdfContent.directions,
  };
  try {
    await API.post("brains", `/pdf/rxDropbox`, {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (err) {
    console.log(err);
    return;
  }
};

// const sendHallandaleRx = async (
//   token,
//   patient,
//   visitId,
//   medList,
//   setPdfEmailError,
//   setPrescriptionSent,
//   setVisitResolutionText,
//   rxTextFromDS,
//   setShowNoteBox
// ) => {
//   try {
//     const body = {
//       patientId: patient._id,
//       visitId,
//       medList
//     };
//     const response = await API.post('brains', '/rx/hallandale', {
//       credentials: 'include',
//       body,
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`,
//       }
//     });
//     if (response.status !== 200) {
//       setPdfEmailError('Something went wrong, please send PDF prescription again.');
//       return true;
//     } else {
//       setPdfEmailError('');
//       setPrescriptionSent(true);
//       setVisitResolutionText(rxTextFromDS(response.data.rxText, patient.firstName, null, null, null, null, null, null, medList));
//       // rxText, firstName, medication, Quantity, Refills, Unit, Directions, pharmacy, medList
//       setShowNoteBox(true);
//       return false;
//     }
//   } catch (err) {
//     console.log(err);
//     setPdfEmailError('Something went wrong, please send PDF prescription again.');
//     return true;
//   }
// }

const sendS3Pdf = async (
  token,
  patient,
  visitId,
  company,
  rxTextFromPDF,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  setShowNoteBox
) => {
  const body = {
    patientId: patient._id,
    company,
    visitId,
  };
  const pdfResponse = await API.post("brains", `/pdf/rxS3`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendEmailPdf = async (
  token,
  patient,
  company,
  visitType,
  rxTextFromPDF,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  setShowNoteBox
) => {
  const body = {
    patientId: patient._id,
    company,
    visitType,
  };
  const pdfResponse = await API.post("brains", `/pdf/rxEmail`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendEmailPdfVar = async (
  token,
  patient,
  visitId,
  company,
  visitType,
  data,
  rxTextFromPDF,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  setShowNoteBox,
  setShowDrugForm
) => {
  const body = {
    patientId: patient._id,
    company,
    visitId,
    visitType,
    data,
  };
  const pdfResponse = await API.post("brains", `/pdf/rxEmail`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowDrugForm(false);
    setShowNoteBox(true);
  }
};

const sendEmailPdfRespiri = async (
  token,
  patient,
  visitId,
  company,
  visitType,
  data,
  setPdfEmailError,
  setPrescriptionSent,
  setShowNoteBox
) => {
  const body = {
    patientId: patient._id,
    company,
    visitId,
    visitType,
    data,
  };
  const pdfResponse = await API.post("brains", `/pdf/rxEmail`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setShowNoteBox(true);
  }
};

const sendLetterPdf = async (
  token,
  patient,
  visitId,
  setPdfEmailError,
  setPrescriptionSent,
  setShowNoteBox
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  const pdfResponse = await API.post("brains", `/pdf/rxLetter`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setShowNoteBox(true);
  }
};

const sendCanadaPdf = async (
  token,
  patient,
  visitId,
  setPdfEmailError,
  setPrescriptionSent,
  updatePatient,
  setCurrentVisit,
  medications
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  if (Array.isArray(medications)) {
    body["medications"] = medications;
  }
  const pdfResponse = await API.post("brains", `/pdf/rxCanada`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return true;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setCurrentVisit(pdfResponse.data.updatedVisit);
    updatePatient(pdfResponse.data.updatedPatient);
    return false;
  }
};

const sendJasePdf = async (
  token,
  patient,
  visitType,
  visitId,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  rxTextFromPDF,
  setShowNoteBox,
  medications
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  if (Array.isArray(medications)) {
    body["medications"] = medications;
  }
  const setPathname = () => {
    switch (visitType) {
      case "jaseCaseJase":
        return "rxApi";
      default:
        return "rxCanada";
    }
  };
  const pathname = setPathname();
  const pdfResponse = await API.post("brains", `/pdf/${pathname}`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return true;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendHallandalePdf = async (
  token,
  patient,
  visitId,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  rxTextFromPDF,
  setShowNoteBox,
  medications
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  if (Array.isArray(medications)) {
    body["medications"] = medications;
  }
  const pdfResponse = await API.post("brains", `/pdf/rxHallandale`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return true;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendUpliftPdf = async (
  token,
  patient,
  visitId,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  rxTextFromPDF,
  setShowNoteBox,
  medications
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  if (Array.isArray(medications)) {
    body["medications"] = medications;
  }
  const pdfResponse = await API.post("brains", `/pdf/rxUplift`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return true;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendCovidPdf = async (
  token,
  patient,
  company,
  visit,
  testResult,
  rxTextFromPDF,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  setShowNoteBox,
  updatePatient
) => {
  const body = {
    patientId: patient._id,
    visitId: visit._id,
    company,
    visitType: visit.visitType,
    testResult,
  };
  const pdfResponse = await API.post("brains", `/pdf/covidEmail`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    updatePatient(pdfResponse.data.patient);
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendDaybreakPdf = async (
  token,
  patient,
  visitId,
  rxTextFromPDF,
  setPdfEmailError,
  setPrescriptionSent,
  setVisitResolutionText,
  setShowNoteBox
) => {
  const body = {
    patientId: patient._id,
    visitId,
  };
  const pdfResponse = await API.post("brains", `/daybreak/rxDentalDevice`, {
    credentials: "include",
    body,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (pdfResponse.status !== 200) {
    setPdfEmailError(
      "Something went wrong, please send PDF prescription again."
    );
    return;
  } else {
    setPdfEmailError("");
    setPrescriptionSent(true);
    setVisitResolutionText(
      rxTextFromPDF(pdfResponse.data.rxText, patient.firstName, patient.email)
    );
    setShowNoteBox(true);
  }
};

const sendInvalidVideo = async (
  token,
  patient,
  visit,
  updatePatient,
  setCurrentVisit,
  setPdfEmailError,
  setIsInvalidOpen
) => {
  try {
    const body = {
      patientId: patient._id,
      visitId: visit._id,
    };
    const response = await API.post("brains", `/lab/invalidVideo`, {
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      setPdfEmailError("Something went wrong, please try again.");
      return;
    } else {
      updatePatient(response.data.patient);
      setCurrentVisit(response.data.visit);
      setPdfEmailError("");
      setIsInvalidOpen(false);
    }
  } catch (err) {
    console.log(err);
    return;
  }
};

const sendWebhooks = async (
  token,
  // allRecentRx,
  patientId,
  visitId
  // patientPharmacy
) => {
  try {
    const response = await API.post("brains", "/dosespot/dsWebhooks", {
      method: "POST",
      credentials: "include",
      body: {
        patientId,
        visitId,
        // allRecent: allRecentRx,
        // patientPharmacy,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    return;
  }
};

const searchMedications = async (token, name, type) => {
  try {
    let url = `/dosespot/medication?name=${name}`;
    if (type === PrescriptionFilterTypesMap.supply.key) {
      url = `/dosespot/supplies/search?name=${name}`;
    }
    const response = await API.get("brains", url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const getMedicationDetails = async (token, name, strength) => {
  try {
    const response = await API.post("brains", `/dosespot/medication`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: {
        name,
        strength,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const getPharmacyDetails = async (token, pharmacyId) => {
  try {
    const response = await API.get(
      "brains",
      `/dosespot/pharmacy/${pharmacyId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const searchPharmacy = async (token, query) => {
  try {
    const response = await API.post("brains", `/dosespot/pharmacy/search`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: query,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

/**
 * API request to get list of pharmacies.
 * @param {string} token - Jwt token of current user.
 * @returns {Promise<{
 *  _id: string,
 *  __v: string,
 *  pharmacyId: string,
 *  name: string,
 * }[]>}
 */
const getPharmacies = async (token) => {
  try {
    const response = await API.get("brains", `/dosespot/pharmacies`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

const createOrUpdatePatientDS = async (token, patientId, company, email) => {
  try {
    const response = await API.post(
      "brains",
      "/dosespot/patient/createOrUpdate",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: {
          patientId,
          company,
          email,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

const addPrescription = async (
  token,
  dsToken,
  dosespotPatientId,
  patientId,
  visitId,
  pharmacyId,
  prescription
) => {
  try {
    const url =
      addPrescriptionUrlMap[prescription.type] || "/dosespot/prescription/add";
    const response = await API.post("brains", url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: {
        dsToken,
        dosespotPatientId,
        patientId,
        visitId,
        pharmacyId,
        prescription,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

const sendPrescription = async (token, dsToken, dosespotPatientId, rxIds) => {
  try {
    const response = await API.post("brains", `/dosespot/prescription/send`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: {
        dsToken,
        dosespotPatientId,
        rxIds,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      error: err,
      errorMessage: err?.response?.data?.error,
    };
  }
};

const getNotificationCount = async (token) => {
  try {
    const response = await API.get("brains", `/dosespot/notifications/count`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getNotificationDetails = async (token) => {
  try {
    const response = await API.get(
      "brains",
      `/dosespot/notifications/details`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
    return;
  }
};

const sendRefillRequestAction = async (
  token,
  dosespotPatientId,
  refillId,
  type,
  responseAction,
  data
) => {
  const response = await API.post("brains", "/dosespot/action", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: {
      dosespotPatientId,
      refillId,
      type,
      responseAction,
      data,
    },
  });
  return response.data;
};

const sendRxChangeAction = async (
  token,
  dosespotPatientId,
  rxChangeType,
  rxChangeRequestId,
  type,
  responseAction,
  data
) => {
  const response = await API.post("brains", "/dosespot/action", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: {
      type,
      responseAction,
      rxChangeRequestId,
      rxChangeType,
      dosespotPatientId,
      data,
    },
  });
  return response.data;
};

const sendRxCancelAction = async (
  token,
  patientId,
  dosespotPatientId,
  rxId
) => {
  const response = await API.post("brains", "/dosespot/prescription/cancel", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: {
      dosespotPatientId,
      patientId,
      rxId,
      comment: "None",
    },
  });
  return response.data;
};

const linkRequestToPatient = async (
  token,
  // rxChange or rxRefill
  type,
  dosespotPatientId,
  // for rxChange only
  rxChangeRequestId,
  // refill only
  refillId
) => {
  const response = await API.post("brains", "/dosespot/action/linkPatient", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: {
      type,
      dosespotPatientId,
      rxChangeRequestId,
      refillId,
    },
  });
  return response.data;
};

const sendRxChangeReconcileAction = async (
  token,
  dosespotPatientId,
  rxChangeRequestId,
  referencedPrescriptionId
) => {
  const response = await API.post("brains", "/dosespot/action/reconcile", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: {
      rxChangeRequestId,
      dosespotPatientId,
      referencedPrescriptionId,
    },
  });
  return response.data;
};

const listPatientMedications = async (token, user, patientId, visitId) => {
  const response = await API.post(
    "brains",
    `/dosespot/allmedications/${patientId}`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: {
        visitId,
        clinicianID: user.dosespotID,
      },
    }
  );
  return response.data;
};

const getVisitTypeFavorites = async (token, visitType) => {
  try {
    const response = await API.get("brains", "/favorite", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.filter(
      (favorite) =>
        visitType.includes(favorite.visitType) || !favorite.visitType
    );
  } catch (err) {
    console.error(err);
    return {
      error: err,
    };
  }
};

const uploadPhotosAdmin = async (
  token,
  visitId,
  images,
  setCurrentVisit,
  setUpdatedPatient
) => {
  try {
    const mappedImages = images.map((image) => {
      return {
        mime: "image/jpeg",
        data: image.encodedString,
      };
    });
    const body = {
      visitId,
      images: mappedImages,
    };
    const response = await API.post("brains", "/visit/uploadPhotosAdmin", {
      method: "POST",
      credentials: "include",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    setCurrentVisit(response.data.visit);
    setUpdatedPatient(response.data.patient);
    return response.data.visit;
  } catch (err) {
    console.log(err);
    return;
  }
};

const rejectIdPhoto = async (
  token,
  visit,
  rejectingPhoto,
  setShowRejectModal
) => {
  try {
    const response = await API.post("brains", `/visit/${visit._id}/photos`, {
      credentials: "include",
      body: {
        name: rejectingPhoto,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      console.error(response);
      return true;
    }
    setShowRejectModal(false);
    return false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const prescribeClick = async (
  token,
  user,
  patient,
  visit,
  setIsPatientLoading,
  setDosespotURL,
  setShowDosespot,
  setShowDrugForm,
  // setCanResolve,
  setShowNoteBox,
  changeTab
) => {
  if (visit.visitType === "EDbrand" || /CA$/.test(visit.visitType)) {
    setShowDrugForm(true);
    return;
  } else {
    const body = {
      clinicianID: user.dosespotID,
      visitId: visit._id,
    };
    setIsPatientLoading(true);
    try {
      const dosespotResponse = await API.post(
        "brains",
        `/dosespot/${patient._id}`,
        {
          credentials: "include",
          body,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (dosespotResponse.status !== 200) {
        console.error(dosespotResponse);
        setIsPatientLoading(false);
        return;
      }
      setIsPatientLoading(false);
      setDosespotURL(dosespotResponse.data.dsURL);
      setShowDosespot(true);
      // setCanResolve(false);
      setShowNoteBox(false);
      changeTab("formData");
      return;
    } catch (err) {
      console.error(err);
      setIsPatientLoading(false);
      return;
    }
  }
};

const getPrescriptionSent = async (
  token,
  user,
  patient,
  company,
  updatePatient,
  setErrorMessage,
  handleCloseWithMedDSV1
) => {
  try {
    const body = {
      clinicianID: user.dosespotID,
      company,
    };
    const response = await API.post(
      "brains",
      `/dosespot/medications/${patient._id}`,
      {
        method: "POST",
        credentials: "include",
        body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      const { patient: responsePatient } = response.data;
      await updatePatient(responsePatient);
      handleCloseWithMedDSV1();
      return;
    } else {
      setErrorMessage(
        "Something went wrong, please close this modal and try again"
      );
      return {
        error: "Something went wrong, please close this modal and try again",
      };
    }
  } catch (err) {
    console.log(err);
    setErrorMessage(
      "Something went wrong, please close this modal and try again"
    );
    return {
      error: "Something went wrong, please close this modal and try again",
    };
  }
};

const getPrescriptionSentDSFull = async (
  token,
  patient,
  _id,
  updatePatient,
  setVisitResolutionText,
  // setAllRecentRx,
  handleCloseWithMed,
  sentRxList
  // patientPharmacy,
  // setPatientPharmacy
) => {
  try {
    const body = {
      visitId: _id,
      sentRxList,
    };
    const response = await API.post(
      "brains",
      `/dosespot/medicationsDSFull/${patient._id}`,
      {
        method: "POST",
        credentials: "include",
        body,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data) {
      const {
        patient: responsePatient,
        // mostRecent,
        rxText,
      } = response.data;
      await updatePatient(responsePatient);
      setVisitResolutionText(rxText);
      // setAllRecentRx(mostRecent);
      // setPatientPharmacy(patientPharmacy);
      handleCloseWithMed();
      return;
    } else {
      return {
        error: "Something went wrong, please close this modal and try again",
      };
    }
  } catch (err) {
    console.log(err);
    return {
      error: "Something went wrong, please close this modal and try again",
    };
  }
};

const updateDocTime = async (token) => {
  try {
    const response = await API.get("brains", "/doc/timeTracking", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
    return true;
  }
};

/**
 * API request to log an event
 * @param {string} token
 * @param {{
 * userId: string
 * firstName: string
 * lastName: string
 * visitType?: string
 * company?: string
 * masterId?: string
 * }} data
 * @returns {Promise<void>}
 */
const logEvent = async (token, eventName, data) => {
  try {
    const response = await API.post("brains", "/logs", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: { ...data, eventName },
    });
    if (response.status !== 200) {
      console.error(response.data);
    }
    return;
  } catch (err) {
    console.error(err);
    return;
  }
};
/**
 * API request to get the list of favorites for the current user.
 * @param {string} token - Jwt token of current user.
 * @param {function} setFavorites - Function to set the favorites.
 * @returns {Promise<void>}
 */
const getFavorites = async (token, setFavorites) => {
  try {
    const response = await API.get("brains", "/favorite", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      return null;
    }
    setFavorites(response.data);
  } catch (err) {
    console.log(err);
    return null;
  }
};

/**
 * API request to creates a favorite using the provided data.
 * @param {string} token - Jwt token of current user.
 * @param {function} setMessage - Function to set error message.
 * @param {object} data - Data to create a favorite.
 * @param {boolean} fromPending - Whether the favorite is from pending list.
 * @returns {Promise<{ favorite: Favorite, pendingDeleted: boolean } | null>} A promise that resolves to the created favorite.
 */
const createFavorite = async (token, setMessage, data, fromPending = false) => {
  try {
    const { _id, __v, requestingCompany, ...create } = data;
    const isValid = favoriteValidator.validate(create);
    if (!isValid) {
      setMessage("Please make sure every field is filled in correctly");
      return null;
    }

    let createPayload = create;
    if (!create.visitType) {
      createPayload = {
        ...create,
        visitType: "",
      };
    }

    const response = await API.post("brains", "/favorite", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        favoriteList: createPayload,
        fromPending,
      },
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return null;
    }

    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return null;
  }
};

/**
 * API request to update a favorite using the provided data.
 * @param {string} token - Jwt token of current user.
 * @param {function} setMessage - Function to set error message.
 * @param {object} data - Data to update a favorite.
 * @param {boolean} fromPending - Whether the favorite is from pending list.
 * @returns {Promise<{ favorite: Favorite, pendingDeleted: boolean } | null>} A promise that resolves to the updated favorite.
 */
const updateFavorite = async (token, setMessage, data, fromPending = false) => {
  try {
    const isValid = favoriteValidator.validate(data);
    if (!isValid) {
      setMessage("Please make sure every field is filled in correctly");
      return null;
    }
    const { clientName, requestingCompany, _id, __v, ...update } = data;
    let updatePayload = update;
    if (!updatePayload.visitType) {
      updatePayload = {
        ...update,
        visitType: "",
      };
    }
    const response = await API.put("brains", "/favorite", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { clientName, update: updatePayload, fromPending },
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return null;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return null;
  }
};

/**
 * API request to bulk update companies of favorites using the provided data.
 * @param {string} token - Jwt token of current user.
 * @param {function} setMessage - Function to set error message.
 * @param {object} data - Data to update a favorite.
 * @returns {Promise<{ favorite: Favorite, pendingDeleted: boolean } | null>} A promise that resolves to the updated favorite.
 */
const bulkUpdateFavoriteCompany = async (token, setMessage, data) => {
  try {
    const response = await API.post(
      "brains",
      "/favorite/bulkUpdateFavoriteCompany",
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      }
    );
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return null;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return null;
  }
};

/**
 * API request to delete a favorite.
 * @param {string} token - Jwt token of current user.
 * @param {function} setMessage - Function to set error message.
 * @param {object} data - Data to delete a favorite.
 * @returns {Promise<boolean>} - A promise that resolves to true if the favorite was deleted successfully.
 */
const deleteFavorite = async (token, setMessage, data = {}) => {
  try {
    const { clientName } = data;
    if (!clientName) {
      setMessage("No client name selected");
      return false;
    }
    const response = await API.del("brains", "/favorite", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { clientName },
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return false;
  }
};

/**
 * API request to get pending favorites.
 * @param {string} token - Jwt token of current user.
 * @param {function} setFavorites - Function to set the pending favorites.
 * @returns {Promise<void>}
 */
const getPendingFavorites = async (token, setFavorites) => {
  try {
    const response = await API.get("brains", "/favorite/pending", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200 || !response.data) {
      console.error(response);
      return null;
    }
    setFavorites(response.data);
  } catch (err) {
    console.log(err);
    return null;
  }
};

const deletePendingFavorite = async (token, setMessage, data = {}) => {
  try {
    const { clientName } = data;
    if (!clientName) {
      setMessage("No client name selected");
      return false;
    }
    const response = await API.post(
      "brains",
      "/favorite/deletePendingFavorite",
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      }
    );
    if (response.status !== 200 || !response.data) {
      console.error(response);
      setMessage("Something went wrong, please try again");
      return null;
    }
    return response.data;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.data && err.response.data.error) {
      setMessage(err.response.data.error);
    } else {
      setMessage("Something went wrong, please try again");
    }
    return false;
  }
};

// const retryWebhook = async (token, pendingFavorite) => {
//   try {
//     const switchCompare = (company) => {
//       switch (true) {
//         case /^bask/.test(company):
//           return "baskName";
//         default:
//           return "clientName";
//       }
//     };
//     const field = switchCompare(pendingFavorite.requestingCompany);
//     const response = await API.post("brains", "/favorite/retryWebhook", {
//       credentials: "include",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       body: {
//         company: pendingFavorite.requestingCompany,
//         medId: pendingFavorite[field],
//       },
//     });
//     if (response.status !== 200) {
//       console.error(response);
//       return null;
//     }
//     return response.data;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// };

const getRxTexts = async (token, setTexts) => {
  try {
    const response = await API.get("brains", "/company/rxTexts", {
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      return [];
    }
    setTexts(response.data);
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * API request to get the list of visit logs.
 * @param {string} token - Jwt token of current user.
 * @param {string} masterId - Master id of the visi.
 * @returns {LogEvent[]}
 */
const getVisitLogs = async (token, masterId) => {
  try {
    const response = await API.get(
      "brains",
      `/logs/byMasterId?masterId=${masterId}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) {
      return [];
    }
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const routerMap = {
  descopeSignin,
  descopeVerify,
  getUser,
  getTransmissionErrors,
  login,
  getPatient,
  getFilteredPatients,
  getCompanies,
  getPlatformCompanyNames,
  createCompany,
  updateCompany,
  getPatients,
  getPatientsAdmin,
  getResolvedStats,
  getResolvedFull,
  getUserResolved,
  getAutoClosed,
  getOrders,
  getProducts,
  getDoctors,
  getDoctorsTrimmed,
  getVisit,
  getGuidelines,
  getPhotos,
  getVideo,
  getChatHistory,
  getCsvRecords,
  getOutputFile,
  searchMedications,
  getMedicationDetails,
  getPharmacyDetails,
  searchPharmacy,
  getPharmacies,
  addPrescription,
  sendPrescription,
  logout,
  checkLogin,
  changePassword,
  cancelOrder,
  createOrder,
  sendChat,
  sendText,
  sendPhotoRequestSMS,
  sendCSMessage,
  updateVisit,
  updateVisitVideoStatus,
  updatePatientDemo,
  updateDoc,
  bulkUpdateDoctor,
  updateAdminUserCompanies,
  switchVisit,
  createDropboxPDF,
  // sendHallandaleRx,
  sendS3Pdf,
  sendEmailPdf,
  sendCovidPdf,
  sendEmailPdfVar,
  sendEmailPdfRespiri,
  sendCanadaPdf,
  sendJasePdf,
  sendHallandalePdf,
  sendUpliftPdf,
  sendLetterPdf,
  sendDaybreakPdf,
  sendInvalidVideo,
  sendWebhooks,
  uploadPhotosAdmin,
  getNotificationCount,
  getNotificationDetails,
  sendRefillRequestAction,
  sendRxChangeAction,
  sendRxCancelAction,
  sendRxChangeReconcileAction,
  linkRequestToPatient,
  listPatientMedications,
  rejectIdPhoto,
  prescribeClick,
  getPrescriptionSent,
  updateDocTime,
  getPrescriptionSentDSFull,
  getVisitTypeFavorites,
  createOrUpdatePatientDS,
  logEvent,
  getFavorites,
  createFavorite,
  updateFavorite,
  bulkUpdateFavoriteCompany,
  deleteFavorite,
  getPendingFavorites,
  deletePendingFavorite,
  // retryWebhook,
  getRxTexts,
  chatList,
  csCompanies,
  csApiList,
  getVisitLogs,
  generateCompanyDocumentation,
};
