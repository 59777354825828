import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
// import Form from 'react-bootstrap/Form';

import MedicineBox from "./MedicineBox";
import "./PrescriptionSelect.scss";

import { routerMap } from "../../utils/router";
import PrescriptionSearch from "./PrescriptionSearch";
import PrescriptionForm from "./PrescriptionForm";
// import { useNotificationsDispatch } from '../notifications/context/notificationsContext';
import {
  getSelectableMedications,
  pdfEmailCompanies,
  pdfEmailVisitType,
  compoundPharms,
} from "./utils/favoriteUtils";
import {
  PrescriptionFilterTypesMap,
  // presriptionTypeOptions,
} from "./utils/prescriptionTypeUtils";
import Pharmacy from "./Pharmacy";
import { usePharmacyDetails } from "./hooks/usePharmacyDetails";

// const daysSupplyLimit = 30;

const {
  sendPrescription,
  getVisitTypeFavorites,
  addPrescription,
  createOrUpdatePatientDS,
  updateDocTime,
  logEvent,
} = routerMap;

const handleSendPrescription = async (dsToken, dosespotPatientId, rxIds) => {
  const token = localStorage.getItem("jwt");
  const res = await sendPrescription(token, dsToken, dosespotPatientId, rxIds);
  return res;
};

const getVisitFavorites = async (visit, pharmacyId, setCanPrescribe) => {
  const token = localStorage.getItem("jwt");
  const isBask = /^bask/.test(visit.company);
  const res = await getVisitTypeFavorites(token, visit.visitType);

  let filtered = res.filter((fav) => {
    if (!fav.company.includes(visit.company)) {
      return null;
    }
    if (
      compoundPharms.includes(pharmacyId) &&
      fav.type === "med" &&
      visit.visitType.includes("weightloss")
    ) {
      return null;
    }
    if (visit.visitType === "chronicMed" && fav.type === "supply") {
      return null;
    }
    if (
      visit.visitType.includes("jaseCasePeds") &&
      !fav.visitType.includes("jaseCasePeds")
    ) {
      return null;
    }
    if (visit.visitType === "stiTest" && fav.visitType !== "stiTest") {
      return null;
    }
    if (
      visit.visitType === "abortion" &&
      ![
        "mAZBAh9mjaB1jKJx6ms0NjmHqYTu6Yyo",
        "U3Ztm7jLLRj79FXqHgfVvh7zR572ndhD",
        "ZP3CAhgAR5j0Ia0xO2zdw2LxkOa0VeiO",
        "sHZ9iOaWCy8DS50Ezu0MPfFLjouSykBz",
      ].includes(fav.clientName)
    ) {
      return null;
    }
    // const shouldReturn = !fav.pharmacyId || fav.pharmacyId === pharmacyId;
    const shouldReturn =
      process.env.REACT_APP_STAGE === "prod"
        ? !fav.pharmacyId || fav.pharmacyId === pharmacyId
        : true;
    return shouldReturn;
  });
  // prevent tirz from showing for sema favorite, vice versa
  if (isBask && visit.visitType.includes("weightloss")) {
    const justPrefNDCs = visit.formObj.patientPreference.map(
      (pref) => pref.packageNDC
    );
    const foundFavs = filtered.filter((fav) =>
      justPrefNDCs.includes(fav.baskName)
    );
    const semaTest = (fav) => /semaglutide/i.test(fav.favoriteName);
    const tirzTest = (fav) => /tirzepatide/i.test(fav.favoriteName);
    if (foundFavs.some((fav) => semaTest(fav))) {
      filtered = filtered.filter((fav) => !tirzTest(fav));
    } else if (foundFavs.some((fav) => tirzTest(fav))) {
      filtered = filtered.filter((fav) => !semaTest(fav));
    }
  }
  // if (
  //   visit.visitType.includes("weightloss") &&
  //   visit.company === "orderlyMeds"
  // ) {
  //   const switchPrefField = isBask ? "packageNDC" : "medId";
  //   const switchFavField = isBask ? "baskName" : "clientName";
  //   const justPrefNDCs = visit.formObj.patientPreference.map(
  //     (pref) => pref[switchPrefField]
  //   );
  //   const foundFavs = filtered.filter(
  //     (fav) =>
  //       justPrefNDCs.includes(fav[switchFavField]) && fav.type === "compound"
  //   );
  //   if (foundFavs.some((fav) => fav.daysSupply <= daysSupplyLimit)) {
  //     filtered = filtered.filter((fav) => {
  //       const isLessOrEqual = fav.daysSupply <= daysSupplyLimit;
  //       return isLessOrEqual;
  //     });
  //   } else if (foundFavs.some((fav) => fav.daysSupply > daysSupplyLimit)) {
  //     filtered = filtered.filter((fav) => fav.daysSupply > daysSupplyLimit);
  //   }
  // }
  // const prefField = isBask ? "packageNDC" : "medId";
  // const favField = isBask ? "baskName" : "clientName";
  // const prefs = visit.formObj.patientPreference?.map((pref) => pref[prefField]).filter((item) => item);
  // if (isBask && prefs && prefs.length && !filtered.some((fav) => prefs.includes(fav[favField]))) {
  //   setCanPrescribe(false);
  // }

  return filtered;
};

const createMedicinePayload = (medicine) => {
  return {
    Name: medicine.name,
    Strength: medicine.strength,
    NDC: medicine.NDC,
    Refills: medicine.refills,
    Quantity: medicine.quantity,
    Directions: medicine.directions,
    PharmacyNotes: medicine.pharmacyNotes,
    DaysSupply: medicine.daysSupply,
    NoSubstitutions: !medicine.allowSubstitution,
    type: PrescriptionFilterTypesMap.medicine.key,
  };
};

const createCompoundPayload = (compound) => {
  return {
    CompoundDescription: compound.name,
    // CompoundDescription: compound.favoriteName || compound.name,
    CompoundIngredients: [
      {
        // Note "DispenseUnitId" will be filled by the backend
        // based on DispenseUnit
        Quantity: compound.quantity,
        FreeText: compound.name,
        IsFreeTextEPCS: false,
      },
    ],
    Refills: compound.refills,
    DispenseUnit: compound.unit,
    Directions: compound.directions,
    NoSubstitutions: !compound.allowSubstitution,
    PharmacyNotes: compound.pharmacyNotes,
    DaysSupply: compound.daysSupply,
    Quantity: compound.quantity,
    type: PrescriptionFilterTypesMap.compound.key,
  };
};

const createSupplyPayload = (supply) => {
  return {
    SupplyId: supply.supplyId,
    Refills: supply.refills,
    DispenseUnit: supply.unit,
    DaysSupply: supply.daysSupply,
    Quantity: supply.quantity,
    NoSubstitutions: !supply.allowSubstitution,
    Directions: supply.directions,
    PharmacyNotes: supply.pharmacyNotes,
    type: PrescriptionFilterTypesMap.supply.key,
  };
};

// Component
const PrescriptionSelect = (props) => {
  const {
    visit,
    user,
    prepopulate,
    visitType,
    patientId,
    weight,
    email,
    visitId,
    patientPreference,
    setMedSelectionCollapsibleState,
    handlePrescriptionSentDSFull,
    handleRefer,
    prescriptionSent,
    pharmacyId,
    setIsPatientLoading,
    updateVisitPharmacy,
    isHourly,
    // hasBeenReferred,
    pdfEmailError,
    sendEmailPdf,
    sendFaxPdf,
    sendHallandalePdf,
    sendUpliftPdf,
  } = props;

  // const { showToast, hideToast } = useNotificationsDispatch();

  const [isSentSuccess, setIsSentSuccess] = useState(false);
  // const [isSavedSuccess, setIsSavedSucess] = useState(false);
  const [hasError, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentMedicine, setCurrentMedicine] = useState({});
  // this one are all the medicines that are added/confirmed for sending
  // const [addedMedicines, setAddedMedicines] = useState([]);
  const [rxIds, setRxIds] = useState([]);
  const [preselectedMedicines, setPreselectedMedicines] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);
  // for display, we'll use null as display All
  const [prescriptionDisplayFilter, setPrescriptionDisplayFilter] =
    useState(null);
  // for controlling form elements
  const [prescriptionType, setPrescriptionType] = useState(
    PrescriptionFilterTypesMap.medicine.key
  );
  const [allowAddNewCompound, setAllowAddNewCompound] = useState(true);
  const [canPrescribe, setCanPrescribe] = useState(true);

  const { patientPharmacy, isLoadingPharmacy } = usePharmacyDetails(pharmacyId);

  useEffect(() => {
    async function fetchFavorites() {
      if (prepopulate) {
        const res = await getVisitFavorites(visit, pharmacyId, setCanPrescribe);
        if (res.error) {
          setErrorMessage(res.error.message);
          return;
        }
        const medicines = getSelectableMedications(
          visit,
          weight,
          email,
          pharmacyId,
          patientPreference,
          res
        );
        setPreselectedMedicines(medicines);
      }
    }
    fetchFavorites();
    setCanPrescribe(true);
  }, [visitId, pharmacyId]);

  const deselectAllMedicine = () => {
    const deselectedMedList = preselectedMedicines.map((m) => {
      if (m.selected && m.original) {
        return { ...m.original, selected: false };
      }
      return { ...m, selected: false };
    });

    setPreselectedMedicines(deselectedMedList);
  };

  // this is for add after search OLD
  const addMedicine = (med) => {
    const updatedMed = { ...med };
    setCurrentMedicine(() => updatedMed);
  };

  const saveMedicine = async (medicine, dsToken, dosespotPatientId) => {
    let medicineToSave = {};
    if (medicine) {
      if (medicine.type === PrescriptionFilterTypesMap.compound.key) {
        medicineToSave = createCompoundPayload(medicine);
      } else if (medicine.type === PrescriptionFilterTypesMap.supply.key) {
        medicineToSave = createSupplyPayload(medicine);
      } else {
        medicineToSave = createMedicinePayload(medicine);
      }
    } else {
      medicineToSave = {
        Name: currentMedicine.value,
        Strength: currentMedicine.strength,
        NDC: currentMedicine.NDC,
        Refills: currentMedicine.refills,
        Quantity: currentMedicine.quantity,
        Directions: currentMedicine.directions,
        PharmacyNotes: currentMedicine.pharmacyNotes,
        DaysSupply: currentMedicine.daysSupply,
        NoSubstitutions: !currentMedicine.allowSubstitution,
      };
    }
    const token = localStorage.getItem("jwt");

    return await addPrescription(
      token,
      dsToken,
      dosespotPatientId,
      patientId,
      visitId,
      pharmacyId,
      // '14973', // for testing
      medicineToSave
    );
  };

  const addNewMedicineToList = () => {
    const newMed = {
      ...currentMedicine,
      name: currentMedicine.value || currentMedicine.name,
      strength: currentMedicine.strength,
      refills: currentMedicine.refills,
      quantity: currentMedicine.quantity,
      directions: currentMedicine.directions,
      pharmacyNotes: currentMedicine.pharmacyNotes,
      daysSupply: currentMedicine.daysSupply,
      allowSubstitution: currentMedicine.allowSubstitution,
      isPatientPreference: false,
      selected: true,
      type: prescriptionType,
    };
    if (currentMedicine?.med?.SupplyId) {
      newMed.supplyId = currentMedicine.med.SupplyId;
      newMed.unit = currentMedicine.dispenseUnitName.value;
    }
    if (prescriptionType === PrescriptionFilterTypesMap.compound.key) {
      newMed.unit = currentMedicine.dispenseUnitName.value;
      setAllowAddNewCompound(false);
    }

    let newMedList = [newMed, ...preselectedMedicines];
    setPreselectedMedicines(newMedList);
    setCurrentMedicine(null);
  };

  const handlePreselectToggle = (med) => {
    setPreselectedMedicines((prev) => {
      return prev.map((m) => {
        if (m.favoriteName) {
          if (m.favoriteName === med.favoriteName) {
            if (m.selected && m.original) {
              return { ...m.original, selected: !m.selected };
            }
            return { ...m, selected: !m.selected };
          }
        } else if (m.name === med.name && m.strength === med.strength) {
          if (m.selected && m.original) {
            return { ...m.original, selected: !m.selected };
          }
          return { ...m, selected: !m.selected };
        }
        return m;
      });
    });
  };

  // Updated Med from Medicine Box
  const handleUpdatedMed = (pendingMed) => {
    setPreselectedMedicines((prev) => {
      return prev.map((m) => {
        if (m.favoriteName) {
          if (m.favoriteName === pendingMed.favoriteName) {
            // store original for reset later
            if (!m.original) {
              return { ...m, ...pendingMed, original: { ...m } };
            } else {
              return { ...m, ...pendingMed };
            }
          }
        } else if (
          m.name === pendingMed.name &&
          m.strength === pendingMed.strength
        ) {
          // store original for reset later
          if (!m.original) {
            return { ...m, ...pendingMed, original: { ...m } };
          } else {
            return { ...m, ...pendingMed };
          }
        }
        return m;
      });
    });
  };

  const handleResetMed = (original) => {
    setPreselectedMedicines((prev) => {
      return prev.map((m) => {
        if (m.favoriteName) {
          if (m.favoriteName === original.favoriteName) {
            return { ...original };
          }
        } else if (
          m.name === original.name &&
          m.strength === original.strength
        ) {
          return { ...original };
        }
        return m;
      });
    });
  };

  const sortByName = (a, b) => {
    const nameA = a.favoriteName || a.name;
    const nameB = b.favoriteName || b.name;
    return nameA.localeCompare(nameB);
  };

  const justSelected = preselectedMedicines
    .filter((med) => med.selected)
    .sort(sortByName);

  const notSelected = preselectedMedicines
    .filter((med) => !med.selected)
    .sort(sortByName);

  const preselectedMedicinesDisplay = [...justSelected, ...notSelected]
    .filter(
      (med) =>
        prescriptionDisplayFilter === null ||
        med.type === prescriptionDisplayFilter
    )
    .map((med, index) => {
      const boxHasError = false;

      return (
        <Fragment key={`${med.name}-${index}`}>
          <MedicineBox
            medicine={med}
            uniqueId={`${med.name}-${index}`}
            selectable={true}
            label={med.favoriteName || med.name || med.label}
            hasError={boxHasError}
            showQuantity={true}
            deletable={false}
            editable={false}
            expandable={true}
            onClick={() => handlePreselectToggle(med)}
            setUpdatedMed={handleUpdatedMed}
            resetMed={handleResetMed}
          />
        </Fragment>
      );
    });

  const onSaveChecking = async () => {
    if (isSending) {
      return;
    }

    if (isHourly) {
      const token = localStorage.getItem("jwt");
      updateDocTime(token);
    }

    const pendingAddPrescriptions = preselectedMedicines.filter(
      (med) => med.selected
    );

    if (rxIds.length === 0 && pendingAddPrescriptions.length === 0) {
      setErrorState(true);
      setErrorMessage("Before saving, please add at least one medication.");
    } else {
      try {
        // showToast({
        //   title: 'Please wait...',
        //   message: "We're still connecting to the provider",
        //   type: 'warning',
        //   delayMs: 10 * 1000,
        // });
        setIsSending(true);
        setIsPatientLoading(true);

        const token = localStorage.getItem("jwt");
        await logEvent(token, "prescribe", {
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          visitType: visit.visitType,
          company: visit.company,
          masterId: visit.masterId,
        });

        if (
          pdfEmailCompanies.includes(visit.company) &&
          pdfEmailVisitType.includes(visitType)
        ) {
          if (visit.company === "jase") {
            await sendFaxPdf(pendingAddPrescriptions);
          } else {
            await sendEmailPdf(pendingAddPrescriptions);
          }
          setErrorState(false);
          setErrorMessage("");
          setIsSentSuccess(true);
          setTimeout(() => {
            // un-disable the UI, add a timeout too prevent accidental double click
            setIsSentSuccess(false);
          }, 5000);
          deselectAllMedicine();
          setMedSelectionCollapsibleState(false);
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }

        if (visit.company === "uplift") {
          await sendUpliftPdf(pendingAddPrescriptions);
          setErrorState(false);
          setErrorMessage("");
          setIsSentSuccess(true);
          setTimeout(() => {
            // un-disable the UI, add a timeout too prevent accidental double click
            setIsSentSuccess(false);
          }, 5000);
          deselectAllMedicine();
          setMedSelectionCollapsibleState(false);
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }

        if (pharmacyId === "243478" && /^bask/i.test(visit.company)) {
          await sendHallandalePdf(pendingAddPrescriptions);
          setErrorState(false);
          setErrorMessage("");
          setIsSentSuccess(true);
          setTimeout(() => {
            // un-disable the UI, add a timeout too prevent accidental double click
            setIsSentSuccess(false);
          }, 5000);
          deselectAllMedicine();
          setMedSelectionCollapsibleState(false);
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }

        // create or update patient in dosespot before adding rx
        const { dsToken, dosespotPatientId } = await createOrUpdatePatientDS(
          token,
          patientId,
          visit.company,
          visit.formObj.email,
        );

        // add all rx
        const rxRes = await Promise.all(
          pendingAddPrescriptions.map(async (med, index) => {
            const res = await saveMedicine(med, dsToken, dosespotPatientId);

            // our BE error
            if (res.error) {
              return {
                error: res.error,
                prescription: med,
              };
            }
            // error from dosespot
            if (res.Result?.ResultCode === "ERROR") {
              return {
                error: res.Result.ResultDescription,
                prescription: med,
              };
            }
            return {
              error: null,
              prescription: med,
              result: res,
            };
          })
        );

        const additionalRxIds = [];
        // TODO: add retry!
        const retryPrescriptions = [];
        rxRes.forEach((res) => {
          if (res.error) {
            retryPrescriptions.push(res.prescription);
          } else {
            additionalRxIds.push(res.result.Id);
          }
        });

        const allRxIds = [...rxIds, ...additionalRxIds];
        if (allRxIds.length === 0 || retryPrescriptions.length) {
          setErrorState(true);
          const names = retryPrescriptions.map((med) => med.name).join(", ");
          setErrorMessage(
            `There was an error saving the prescriptions. ${names}`
          );
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }
        // send all rx
        const res = await handleSendPrescription(
          dsToken,
          dosespotPatientId,
          allRxIds
        );

        // hideToast();
        const containsErrors =
          res?.length && res.filter((r) => r.Result.ResultCode === "ERROR");
        if (containsErrors?.length) {
          setErrorState(true);
          const messages = containsErrors[0].Result.ResultDescription;
          setErrorMessage(messages);
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }
        if (res.error) {
          setErrorState(true);
          setErrorMessage(res.errorMessage || res.error.toString());
          setIsSending(false);
          setIsPatientLoading(false);
          return;
        }

        // sent successful handling
        const handleRes = await handlePrescriptionSentDSFull(
          rxRes
          // patientPharmacy
        );
        if (handleRes?.error) {
          setErrorState(true);
          setErrorMessage(handleRes.error);
          setIsSending(false);
          return;
        }

        setErrorState(false);
        setErrorMessage("");
        setIsSentSuccess(true);
        setTimeout(() => {
          // un-disable the UI, add a timeout too prevent accidental double click
          setIsSentSuccess(false);
        }, 5000);
        deselectAllMedicine();
        setMedSelectionCollapsibleState(false);
      } catch (err) {
        console.error(err);
        setErrorState(true);
        setErrorMessage(err);
        setIsPatientLoading(false);
        // hideToast();
      }
      setIsSending(false);
      setIsPatientLoading(false);
    }
  };

  const buttonSavingClassName = isSending
    ? " loadingButton savingInProgress"
    : "";
  const bodySavingClassName = isSending ? " disabled" : "";
  const containerSavingClassName = isSending ? " savingInProgress" : "";
  // const hasBeenPrescribed = ["5f923ff775230b0008abacf4","5e8d4b904e3f47f93c07b01c"].includes(user._id)
  //   ? false
  //   : visit.rxHistory.length > 0;

  let prescriptionForm = null;
  if (currentMedicine?.med?.Strength) {
    prescriptionForm = (
      <PrescriptionForm
        prescriptionType={prescriptionType}
        currentMedicine={currentMedicine}
        setCurrentMedicine={setCurrentMedicine}
        savePrescription={addNewMedicineToList}
        isSaving={isSaving}
        buttonName="Add Medicine"
      />
    );
  } else if (currentMedicine?.med?.SupplyId) {
    prescriptionForm = (
      <PrescriptionForm
        prescriptionType={prescriptionType}
        currentMedicine={currentMedicine}
        setCurrentMedicine={setCurrentMedicine}
        savePrescription={addNewMedicineToList}
        isSaving={isSaving}
        buttonName="Add Supply"
      />
    );
  } else if (prescriptionType === PrescriptionFilterTypesMap.compound.key) {
    prescriptionForm = (
      <PrescriptionForm
        prescriptionType={prescriptionType}
        currentMedicine={currentMedicine}
        setCurrentMedicine={setCurrentMedicine}
        savePrescription={addNewMedicineToList}
        isSaving={isSaving}
        allowAddNewCompound={allowAddNewCompound}
        setAllowAddNewCompound={setAllowAddNewCompound}
        buttonName="Add To Compound"
      />
    );
  }

  return (
    <div
      className={`prescriptionSelectionContainer${containerSavingClassName}`}
    >
      {!!user.npi && (
        <div className="medicineSelectionHeader">
          <button
            type="button"
            onClick={handleRefer}
            disabled={prescriptionSent}
            className="visitButton resolveButton referButton"
          >
            Refer
          </button>
          <button
            type="button"
            data-test-id="send-button"
            disabled={isSending || !canPrescribe}
            // disabled={isSending || hasBeenReferred || hasBeenPrescribed}
            className={`dsFullPrescribe resolveButton${buttonSavingClassName}`}
            onClick={() => onSaveChecking()}
          >
            {isSending ? <div className="loader" /> : "Prescribe"}
          </button>
        </div>
      )}

      {/* <div className='medicineSelectionHeader'>
        <Form.Select
          onChange={(e) => {
            setPrescriptionType(e.target.value);
            if (e.target.value === PrescriptionFilterTypesMap.compound.key) {
              setAllowAddNewCompound(true);
            }
          }}
          size='sm'
          aria-label='Prescription Type Select'
        >
          {presriptionTypeOptions.map((option) => {
            return <option value={option.key}>{option.label}</option>;
          })}
        </Form.Select>
      </div> */}
      <div className="border-top my-1"></div>

      <div className={`medicineSelectionBody${bodySavingClassName}`}>
        {/* when there is an error, show error message */}
        {hasError && (
          <div className="medicineSelectionMessage medicineSelectionErrorMessage">
            {errorMessage}
          </div>
        )}
        {pdfEmailError && (
          <div className="medicineSelectionMessage medicineSelectionErrorMessage">
            {pdfEmailError}
          </div>
        )}

        {/* if successfuly saved, a success message will show up 5 secs */}
        {isSentSuccess && (
          <div className="medicineSelectionMessage medicineSelectionSuccessMessage">
            Prescription has been sent successfully.
          </div>
        )}

        <Pharmacy
          originalPharmacyId={visit?.formObj?.pharmacyId}
          currentPharmacyId={pharmacyId}
          patientPharmacy={patientPharmacy}
          isLoadingPharmacy={isLoadingPharmacy}
          updateVisitPharmacy={updateVisitPharmacy}
        />
        {!!user.npi &&
          prescriptionType !== PrescriptionFilterTypesMap.compound.key && (
            <PrescriptionSearch
              prescriptionType={prescriptionType}
              currentMedicine={currentMedicine}
              onMedicineSelected={addMedicine}
            />
          )}

        {/* Form to add med / supply / ingredient for compound */}
        {prescriptionForm}

        <div className="deselect-all-med-button" onClick={deselectAllMedicine}>
          Deselect All
        </div>

        <div className="medicinesChoicesHolder">
          {preselectedMedicinesDisplay}
        </div>
      </div>
    </div>
  );
};

PrescriptionSelect.propTypes = {
  prepopulate: PropTypes.bool,
  patientId: PropTypes.string,
  visitId: PropTypes.string,
  visitType: PropTypes.string,
  setShowNoteBox: PropTypes.func,
  patientPreference: PropTypes.array,
};

export default PrescriptionSelect;
